<template>
  <div>
    <h1 class="text-h6 light--text pb-3">Dataplans</h1>
    <v-skeleton-loader v-if="isLoading" type="table" dark></v-skeleton-loader>

    <v-btn
      rounded
      large
      color="dark lightGreen--text"
      class="my-3"
      v-if="!isLoading"
      :disabled="isLoading"
      @click="getDataplans()"
      >Refresh</v-btn
    >

    <v-simple-table v-if="!isLoading" dark>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Display Name</th>
            <th class="text-left">Charge Over ID</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="dataplan in dataplans" :key="dataplan.chrgOvrId">
            <td class="light--text">{{ dataplan.displayName }}</td>
            <td class="lightGreen--text">{{ dataplan.chrgOvrId }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import axios from "../../axios/axios";

export default {
  data: () => ({
    isLoading: false,
    dataplans: [],
  }),
  mounted() {
    this.getDataplans();
  },
  methods: {
    async getDataplans() {
      this.isLoading = true;
      try {
        const res = await axios.get("/dataplans");
        this.dataplans = res.data;
      } catch (error) {
        alert(error.response.data.message);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style>
</style>