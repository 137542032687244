<template>
    <v-app>
    <div class="all darker">
      <top-nav-bar></top-nav-bar>
      <router-view class="padding-top-nav" />
    </div> 
  </v-app>
</template>

<script>
import router from "./router/index";
import TopNavBar from './components/Navigation/TopNavBar.vue'

export default {
  name: 'App',
  components: {
    TopNavBar
  },
   methods: {
    autoLogin() {
      const expirationTime = new Date(this.expiration).getTime();
      const now = new Date().getTime();

      if (expirationTime > now) {
        this.$store.dispatch("autoLogin");
      } else {
        if (this.$route.path != "/login") router.push({ path: "/login" });
      }
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.getIsLoggedIn;
    },
    username() {
      return this.$store.getters.getUsername;
    },
    token() {
      return this.$store.getters.getToken;
    },
    expiration() {
      return this.$store.getters.getExpiration;
    },
  },
  beforeMount() {
    this.autoLogin();
  },
};
</script>

<style>
.all {
  min-height: 100vh;
}
.padding-top-nav {
  padding-top: 64px;
}
.pointer {
  cursor: pointer;
}
</style>