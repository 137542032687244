<template>
  <div>
    <v-container>
      <v-btn text class="lightBlue--text" @click="backClicked()">
        <v-icon>mdi-arrow-left</v-icon>Back
      </v-btn>

      <div class="text-center mb-12">
        <v-row justify-sm="center" align="center">
          <h1
            :class="
              tower.color +
              '--text text-h3 font-weight-light text-center pt-10 pb-6 mr-5'
            "
          >
            {{ tower.name }}
            <v-progress-circular
              v-if="isLoading"
              indeterminate
              color="light"
              class="ml-3 pt-5"
            ></v-progress-circular>
          </h1>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  v-if="!isLoading && isTowerUp"
                  x-large
                  color="lightGreen"
                  class="pt-5"
                  >mdi-arrow-up-circle</v-icon
                >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  v-if="!isLoading && !isTowerUp"
                  x-large
                  color="darkRed"
                  class="pt-5"
                  >mdi-arrow-down-circle</v-icon
                >
              </span>
            </template>
            <span>Tower is {{ getTooltipText() }}</span>
          </v-tooltip>
        </v-row>

        <h2 class="text-h6 font-weight-lighter lightGreen--text">
          {{ tower.ipAddress }}
        </h2>
        <h2 class="text-h6 font-weight-lighter grey--text">
          {{ tower.location }}
        </h2>

        <v-btn
          large
          rounded
          class="mt-9"
          color="dark lighter--text"
          link
          :to="'/tower-customers/' + towerId"
        >
          View Customers
          <v-icon color="lighter">mdi-arrow-right</v-icon>
        </v-btn>
      </div>

      <v-divider dark class="pt-10"></v-divider>

      <monitor-backups
        v-if="!isLoadingTower"
        :tower="tower"
        class="pb-7"
      ></monitor-backups>

      <v-expansion-panels dark v-if="!isLoadingTower">
        <v-expansion-panel>
          <v-expansion-panel-header class="dark light--text"
            >Neighbors and PPPoe</v-expansion-panel-header
          >
          <v-expansion-panel-content color="dark" class="light--text">
            <div class="d-flex justify-space-around">
              <monitor-neighbors
                v-if="!isLoadingTower"
                :tower="tower"
                class="pb-7 pr-3 flex-grow-1"
              ></monitor-neighbors>
              <monitor-pppoe
                v-if="!isLoadingTower"
                :tower="tower"
                class="pb-7 pl-3 flex-grow-1"
              ></monitor-pppoe>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>
import axios from "../axios/axios";
import MonitorBackups from "../components/Tower/Monitoring/MonitorBackups.vue";
import MonitorNeighbors from "../components/Tower/Monitoring/MonitorNeighbors.vue";
import MonitorPppoe from "../components/Tower/Monitoring/MonitorPppoe.vue";

export default {
  data: () => ({
    isLoading: true,
    isLoadingTower: true,
    isTowerUp: false,
    tower: {},
  }),
  components: {
    MonitorBackups,
    MonitorNeighbors,
    MonitorPppoe,
  },
  computed: {
    towerId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    this.getTower();
  },
  methods: {
    async getTower() {
      try {
        const res = await axios.get("/towers/" + this.towerId);
        this.tower = res.data;
      } catch (error) {
        alert(error.response.data.message);
      }
      this.isLoadingTower = false;
      this.getTowerStatus();
    },
    async getTowerStatus() {
      if (!this.isLoadingTower) {
        try {
          const res = await axios.post("/towers-monitor/identity", this.tower);
          if (res.data !== this.tower.name) {
            alert(
              "Warning! The tower name does not match the router's identity. The backup system and other features may not work unless the tower names match."
            );
          }
          this.isTowerUp = true;
        } catch (error) {
          this.isTowerUp = false;
        }
        this.isLoading = false;
      }
    },
    getTooltipText() {
      if (this.isTowerUp) {
        return "UP";
      } else {
        return "DOWN";
      }
    },
    backClicked() {
      history.back();
    },
  },
};
</script>

<style>
</style>