import Vue from 'vue'
import Vuex from 'vuex'
import axios from "../axios/axios";
import router from "../router/index";

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    isLoggedIn: false,
    email: "",
    name: "",
    token: "",
    expiration: "",
    userType: ""
  },

  mutations: {
    login: (state, payload) => {
      const expiration = new Date(
        new Date().getTime() + payload.expiresIn
      );

      localStorage.setItem("name", payload.name);
      localStorage.setItem("email", payload.email);
      localStorage.setItem("token", payload.token);
      localStorage.setItem("expiration", expiration);
      localStorage.setItem("userType", payload.userType);

      axios.defaults.headers.common['Authorization'] = `Bearer ${payload.token}`;
      axios.defaults.headers.post['Content-Type'] = 'application/json'

      state.isLoggedIn = true;
      state.name = payload.name;
      state.email = payload.email;
      state.token = payload.token;
      state.expiration = expiration;
      state.userType = payload.userType;
    },

    autoLogin: (state) => {
      let name = localStorage.getItem("name");
      let email = localStorage.getItem("email");
      let token = localStorage.getItem("token");
      let expiration = new Date(localStorage.getItem("expiration"));
      let userType = localStorage.getItem("userType");

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      axios.defaults.headers.post['Content-Type'] = 'application/json'

      state.isLoggedIn = true;
      state.name = name;
      state.email = email;
      state.token = token;
      state.expiration = expiration;
      state.userType = userType;
    },

    logout: (state) => {
      localStorage.removeItem("name");
      localStorage.removeItem("email");
      localStorage.removeItem("token");
      localStorage.removeItem("expiration");
      localStorage.removeItem("userType");

      state.isLoggedIn = false;
      state.email = "";
      state.token = "";
      state.expiration = "";
      state.userType = "";

      router.push({ path: '/login' });
    },

    logoutTimer: (state) => {
      const now = new Date().getTime();
      const expiration = new Date(state.expiration).getTime();

      setTimeout(() => store.dispatch({ type: "logout" }), expiration - now);
    },
  },

  actions: {
    login: (context, payload) => {
      context.commit("login", payload);
      context.dispatch("logoutTimer");
    },

    autoLogin: (context) => {
      context.commit("autoLogin");
      context.dispatch("logoutTimer");
    },

    logout: (context) => {
      context.commit("logout");
    },

    logoutTimer: (context) => {
      context.commit("logoutTimer");
    }
  },

  getters: {
    getBaseURL: () => {
      let BASE_URL = "http://localhost:3000/";

      if (process.env.NODE_ENV === 'production') {
        BASE_URL = "https://api.ubinternet.com/";
      }

      return BASE_URL
    },
    getIsLoggedIn: (state) => {
      return state.isLoggedIn;
    },
    getUsername: (state) => {
      if (!state.name) return localStorage.getItem('name');
      return state.name;
    },
    getEmail: (state) => {
      if (!state.email) return localStorage.getItem('email');
      return state.email;
    },
    getToken: (state) => {
      if (!state.token) return localStorage.getItem('token');
      return state.token;
    },
    getExpiration: (state) => {
      if (!state.expiration) return localStorage.getItem('expiration');
      return state.expiration;
    },
    getUserType: (state) => {
      if (!state.userType) return localStorage.getItem('userType');
      return state.userType;
    }
  }
});

export default store;