<template>
  <div>
    <v-row justify-sm="center" class="pb-9">
      <v-col sm="4">
        <p class="grey--text">
          Name: <span class="light--text">{{ username }}</span>
        </p>
        <p class="grey--text">
          Email: <span class="light--text">{{ email }}</span>
        </p>
        <p class="grey--text">
          User Type: <span class="light--text">{{ userType }}</span>
        </p>
      </v-col>
    </v-row>

    <v-form v-model="valid">
      <v-row justify-sm="center">
        <v-col sm="4">
          <h1 class="text-h5 text-center light--text pb-5">Change Password</h1>
        </v-col>
      </v-row>
      <v-row justify-sm="center">
        <v-col sm="4" class="pa-0">
          <v-text-field
            v-model="newPassword"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :type="showPassword ? 'text' : 'password'"
            label="Password"
            :disabled="isLoading"
            :rules="[rules.required, rules.min]"
            counter
            dense
            outlined
            rounded
            required
            dark
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="isLoading" justify-sm="center">
        <v-progress-circular
          indeterminate
          color="lighter"
        ></v-progress-circular>
      </v-row>

      <v-row justify-sm="center">
        <v-btn
          rounded
          large
          color="dark lightBlue--text"
          class="mt-3"
          v-if="!isLoading"
          :disabled="isLoading"
          @click="submitForm()"
          >Update Password</v-btn
        >
      </v-row>
      <v-row justify-sm="center">
        <v-col sm="5">
          <p v-if="showError" class="light--text mb-3">{{ errorText }}</p>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import axios from "../../axios/axios";

export default {
  data: () => ({
    isLoading: false,
    valid: false,
    showPassword: false,
    errorText: "",
    showError: false,
    user: {},
    newPassword: "",
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
  }),
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    email() {
      return this.$store.getters.getEmail;
    },
    userType() {
      return this.$store.getters.getUserType;
    },
    token() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    submitForm() {
      if (this.valid) {
        this.changePassword();
      } else {
        this.showError = true;
        this.errorText = "Invalid fields in form.";
      }
    },
    async changePassword() {
      this.isLoading = true;
      try {
        this.showError = false;
        this.user = {
          name: 'clayton',
          email: this.email,
          password: this.newPassword,
          userType: this.userType,
        };

        const res = await axios.put("/users/change-password", this.user);
        alert(res.data.message);
        this.newPassword = "";
      } catch (error) {
        this.showError = true;
        this.errorText = error.response.data.message;
      }
      this.isLoading = false;
    },
  },
};
</script>

<style>
</style>