<template>
  <div>
    <v-sheet width="160" height="160" @click="towerClicked()" class="pointer dark rounded-xl">
      <v-col>
        <tower-square-dropdown
          :tower="tower"
          v-on:reload="reloadTowers"
        ></tower-square-dropdown>

        <v-row class="d-flex justify-center pt-2">
          <p class="text-h6 font-weight-light darker--text text-center pa-1">
            <span :class="tower.color + '--text'">
              {{ tower.name }}
            </span>
          </p>
        </v-row>
      </v-col>
    </v-sheet>
  </div>
</template>

<script>
import TowerSquareDropdown from "./TowerSquareDropdown.vue";
import router from '../../router/index'

export default {
  props: {
    tower: {
      type: Object,
    },
  },
  components: {
    TowerSquareDropdown,
  },
  methods: {
    towerClicked() {
      router.push({ path: '/tower-monitor/' + this.tower._id })
    },
    reloadTowers() {
      this.$emit("reload");
    },
  },
};
</script>