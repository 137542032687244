<template>
  <div>
    <h1 class="text-h6 light--text pb-3">Users</h1>
    <v-skeleton-loader v-if="isLoading" type="table" dark></v-skeleton-loader>

    <v-btn
      rounded
      large
      color="dark lightBlue--text"
      class="my-3"
      v-if="!isLoading"
      :disabled="isLoading"
      @click="getUsers()"
      >Refresh</v-btn
    >

    <v-simple-table v-if="!isLoading" dark>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Email</th>
            <th class="text-left">User Type</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user._id">
            <td class="light--text">{{ user.name }}</td>
            <td class="light--text">{{ user.email }}</td>
            <td class="light--text">{{ user.userType }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import axios from "../../axios/axios";

export default {
  data: () => ({
    isLoading: false,
    users: [],
  }),
  mounted() {
    this.getUsers();
  },
  methods: {
    async getUsers() {
      this.isLoading = true;
      try {
        const res = await axios.get("/users");
        this.users = res.data;
      } catch (error) {
        alert(error.response.data.message);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style>
</style>