<template>
  <div>
    <v-container>
      <v-btn text class="lightBlue--text" @click="backClicked()">
        <v-icon>mdi-arrow-left</v-icon>Back
      </v-btn>

      <h1 class="text-h3 font-weight-light light--text text-center pt-6 pb-6">
        {{ title }}
      </h1>
      <v-card dark class="pt-4 px-8 pb-4">
        <v-form v-model="valid">
          <v-card-title class="light--text pb-6">{{ title }}</v-card-title>
          <v-row justify-sm="center">
            <v-col sm="11">
              <v-text-field
                v-model="customer.firstName"
                label="First Name"
                placeholder="Last Name"
                :disabled="isLoading"
                :rules="[rules.required]"
                dense
                outlined
                rounded
                required
                dark
              ></v-text-field>

              <v-text-field
                v-model="customer.lastName"
                label="Last Name"
                placeholder="Last Name"
                :disabled="isLoading"
                :rules="[rules.required]"
                dense
                outlined
                rounded
                required
                dark
              ></v-text-field>

              <v-text-field
                v-model="customer.company"
                label="Business Name (if applicable)"
                placeholder="Business Name (if applicable)"
                :disabled="isLoading"
                dense
                outlined
                rounded
                dark
              ></v-text-field>

              <v-select
                :items="dataplans"
                label="Dataplan"
                item-text="displayName"
                v-model="selectedDataplan"
                outlined
                rounded
                dense
                dark
                :rules="[rules.required]"
              ></v-select>

              <v-select
                :items="towers"
                item-text="name"
                label="Tower"
                v-model="customer.tower"
                outlined
                rounded
                dense
                dark
                :rules="[rules.required]"
              ></v-select>

              <v-text-field
                v-model="customer.email"
                label="Email"
                placeholder="Email"
                :disabled="isLoading"
                dense
                outlined
                rounded
                dark
              ></v-text-field>

              <v-text-field
                v-model="customer.phone"
                label="Phone"
                placeholder="Phone"
                :disabled="isLoading"
                dense
                outlined
                rounded
                dark
              ></v-text-field>

              <v-text-field
                v-model="customer.address1"
                label="Address 1"
                placeholder="Address 1"
                :disabled="isLoading"
                dense
                outlined
                rounded
                dark
              ></v-text-field>

              <v-text-field
                v-model="customer.address2"
                label="Address 2"
                placeholder="Address 2"
                :disabled="isLoading"
                dense
                outlined
                rounded
                dark
              ></v-text-field>

              <v-text-field
                v-model="customer.city"
                label="City"
                placeholder="City"
                :disabled="isLoading"
                dense
                outlined
                rounded
                dark
              ></v-text-field>

              <v-select
                v-model="customer.state"
                :items="states"
                color="dark"
                label="State"
                outlined
                rounded
                dense
                dark
              ></v-select>

              <v-text-field
                v-model="customer.zip"
                label="Zip Code"
                placeholder="Zip Code"
                :disabled="isLoading"
                dense
                outlined
                rounded
                dark
              ></v-text-field>

              <p class="light--text pl-5">Date of Birth:</p>
              <v-date-picker
                v-model="dateOfBirth"
                color="darkRed"
                class="pb-8 pl-5"
              ></v-date-picker>

              <v-textarea
                v-model="customer.notes"
                label="Notes"
                placeholder="Notes"
                :disabled="isLoading"
                outlined
                rounded
                auto-grow
                clearable
                dark
              ></v-textarea>

              <signature-component
                v-if="!isLoading"
                class="pb-5"
                :signature="customer.signature"
                @updateSignature="updateSignatureMethod"
              ></signature-component>

              <v-divider class="pb-5"></v-divider>

              <p class="light--text">Employee Info:</p>

              <v-text-field
                v-model="customer.salesName"
                label="Sales Person"
                placeholder="Sales Person"
                :disabled="isLoading"
                dense
                outlined
                rounded
                dark
              ></v-text-field>

              <v-text-field
                v-model="customer.installersName"
                label="Installer Name"
                placeholder="Installer Name"
                :disabled="isLoading"
                dense
                outlined
                rounded
                dark
              ></v-text-field>

              <v-text-field
                v-model="customer.installDate"
                label="Install Date"
                placeholder="Install Date"
                :disabled="isLoading"
                dense
                outlined
                rounded
                dark
              ></v-text-field>

              <v-divider class="pb-5"></v-divider>

              <p class="light--text">Phone Customer Info:</p>

              <v-text-field
                v-model="customer.previousCarrier"
                label="Previous Carrier Name"
                placeholder="Previous Carrier Name"
                :disabled="isLoading"
                dense
                outlined
                rounded
                dark
              ></v-text-field>

              <v-text-field
                v-model="customer.previousAccountNumber"
                label="Previous Account Number"
                placeholder="Previous Account Number"
                :disabled="isLoading"
                dense
                outlined
                rounded
                dark
              ></v-text-field>

              <v-text-field
                v-model="customer.previousAccountPin"
                label="Previous Account Pin"
                placeholder="Previous Account Pin"
                :disabled="isLoading"
                dense
                outlined
                rounded
                dark
              ></v-text-field>

              <div v-if="isNewCustomer">
                <v-divider class="pb-5"></v-divider>

                <p class="light--text">ChargeOver Info:</p>

                <v-text-field
                  v-model="creditInfo.nameOnTheCard"
                  label="Name on Card"
                  placeholder="Name on Card"
                  :disabled="isLoading"
                  dense
                  outlined
                  rounded
                  dark
                ></v-text-field>

                <v-text-field
                  v-model="creditInfo.cardNumber"
                  label="Card Number"
                  placeholder="Card Number"
                  :disabled="isLoading"
                  dense
                  outlined
                  rounded
                  dark
                ></v-text-field>

                <v-row class="pa-3">
                  <v-select
                    v-model="creditInfo.expiryMonth"
                    :items="months"
                    color="dark"
                    class="mr-3"
                    label="Expiration Month"
                    outlined
                    rounded
                    dense
                    dark
                  ></v-select>

                  <v-text-field
                    v-model="creditInfo.expiryYear"
                    label="Expiration Year"
                    placeholder="Expiration Year"
                    :disabled="isLoading"
                    dense
                    outlined
                    rounded
                    dark
                  ></v-text-field>
                </v-row>
              </div>

              <p v-if="showError" class="light--text mb-16">{{ errorText }}</p>

              <v-row class="py-4">
                <v-btn
                  rounded
                  x-large
                  class="ml-4"
                  color="lightBlue lighter--text"
                  v-if="!isLoading"
                  :disabled="isLoading"
                  @click="saveCustomer()"
                  >Save
                </v-btn>

                <v-btn
                  rounded
                  x-large
                  class="ml-4 mr-3"
                  color="darkRed lighter--text"
                  v-if="!isLoading"
                  :disabled="isLoading"
                  @click="deleteCustomer()"
                  >Delete
                </v-btn>
              </v-row>

              <v-btn
                rounded
                large
                block
                class="mt-5 mb-5"
                color="dark lighter--text"
                v-if="!isLoading"
                :disabled="isLoading"
                @click="backClicked()"
                >CANCEL</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from "../axios/axios";
import signatureComponent from "../components/Customer/SignatureComponent.vue";

export default {
  mounted() {
    this.onShow();
  },
  components: {
    signatureComponent,
  },
  computed: {
    customerId() {
      return this.$route.params.id;
    },
    towerName() {
      return this.$route.query.tower;
    },
  },
  methods: {
    onShow() {
      window.scrollTo(0, 0);

      if (this.customerId !== "create") {
        this.title = "Edit Customer";
        this.getCustomer();
      } else {
        this.title = "New Customer";
        this.isNewCustomer = true;
        if (this.towerName !== null) {
          this.customer.tower = this.towerName;
        }
        this.getTowers();
        this.getDataplans();
      }
    },
    async getCustomer() {
      this.isLoading = true;
      try {
        const res = await axios.get("/customers/" + this.customerId);
        this.customer = res.data;

        if (this.customer.birthday) {
          this.dateOfBirth = new Date(this.customer.birthday)
            .toISOString()
            .substr(0, 10);
        }

        this.getTowers();
        this.getDataplans();
      } catch (error) {
        alert(error.response.data.message);
      }
      this.isLoading = false;
    },
    async saveCustomer() {
      if (!this.valid) {
        this.errorText = "Missing values.";
        this.showError = true;
        return;
      }
      this.isLoading = true;

      if (this.dateOfBirth) {
        this.customer.birthday = this.dateOfBirth;
      }

      for (const dataplan of this.dataplans) {
        if (dataplan.displayName === this.selectedDataplan) {
          this.customer.dataPlan = dataplan.chrgOvrId.toString();
          break;
        }
      }

      if (this.isNewCustomer) {
        try {
          await axios.post("/customers", {
            customer: this.customer,
            creditInfo: this.creditInfo,
          });

          this.backClicked();
        } catch (error) {
          alert(error.response.data.message);
        }
      } else {
        try {
          const res = await axios.put("/customers", this.customer);
          alert(res.data.message);
          this.onShow();
        } catch (error) {
          alert(error.response.data.message);
        }
      }
      this.isLoading = false;
    },
    async deleteCustomer() {
      if (!this.isNewCustomer) {
        if (
          confirm(
            `You are about to delete ${
              this.customer.firstName + " " + this.customer.lastName
            }'s record.`
          )
        ) {
          try {
            await axios.delete("/customers/" + this.customer._id);
            this.backClicked();
          } catch (error) {
            alert(error.response.data.message);
          }
        }
      } else {
        this.backClicked();
      }
    },
    async getTowers() {
      try {
        const res = await axios.get("/towers");
        this.towers = res.data;
      } catch (error) {
        alert(error.response.data.message);
      }
    },
    async getDataplans() {
      try {
        const res = await axios.get("/dataplans");
        this.dataplans = res.data;

        for (const dataplan of this.dataplans) {
          if (dataplan.chrgOvrId.toString() === this.customer.dataPlan) {
            this.selectedDataplan = dataplan.displayName;
            break;
          }
        }
      } catch (error) {
        alert(error.response.data.message);
      }
    },
    updateSignatureMethod(value) {
      this.customer.signature = value;
    },
    backClicked() {
      history.back();
    },
  },
  data: () => ({
    isLoading: false,
    title: "",
    isNewCustomer: false,
    customer: {},
    creditInfo: {},
    towers: [],
    dataplans: [],
    selectedDataplan: "",
    dateOfBirth: "",
    errorText: "",
    showError: false,
    valid: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    states: [
      "UT",
      "PA",
      "AK",
      "AL",
      "AR",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DE",
      "FL",
      "GA",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
  }),
};
</script>

<style>
</style>