<template>
  <v-card dark class="pt-4 px-8 pb-4">
    <v-card-title class="light--text">{{ title }}</v-card-title>
    <v-form v-model="valid">
      <v-row justify-sm="center">
        <v-col sm="11">
          <v-text-field
            v-model="tower.name"
            label="Tower Name"
            placeholder="Tower Name"
            :disabled="isLoading"
            :rules="[rules.required]"
            outlined
            rounded
            clearable
            required
            dark
          ></v-text-field>

          <span class="light--text">Tower Color:</span>
          <v-tabs
            fixed-tabs
            center-active
            background-color="dark"
            class="pb-9"
            dark
          >
            <v-tab
              v-for="color of colors"
              :key="color.color"
              :class="color.color + '--text'"
              @change="tower.color = color.color"
              >{{ color.name }}</v-tab
            >
          </v-tabs>

          <v-text-field
            v-model="tower.ipAddress"
            label="ip Address"
            placeholder="ip Address"
            :disabled="isLoading"
            dense
            outlined
            rounded
            clearable
            dark
          ></v-text-field>

          <v-text-field
            v-model="tower.port"
            label="API Port"
            placeholder="API Port"
            :disabled="isLoading"
            dense
            outlined
            rounded
            clearable
            dark
          ></v-text-field>

          <v-text-field
            v-model="tower.username"
            label="API Username"
            placeholder="API Username"
            :disabled="isLoading"
            dense
            outlined
            rounded
            clearable
            dark
          ></v-text-field>

          <v-text-field
            v-model="tower.password"
            label="API Password"
            placeholder="API Password"
            :disabled="isLoading"
            dense
            outlined
            rounded
            clearable
            dark
          ></v-text-field>

          <v-text-field
            v-model="tower.location"
            label="Physical Location"
            placeholder="Physical Location"
            :disabled="isLoading"
            dense
            outlined
            rounded
            clearable
            dark
          ></v-text-field>

          <p class="light--text" v-if="showError">{{ errorText }}</p>

          <v-btn
            rounded
            large
            block
            color="lightBlue lighter--text"
            v-if="!isLoading"
            :disabled="isLoading"
            @click="saveClicked()"
            >SAVE</v-btn
          >

          <v-btn
            rounded
            large
            block
            class="mt-5 mb-5"
            color="darkRed light--text"
            v-if="!isLoading"
            :disabled="isLoading"
            @click="deleteTower()"
            >DELETE</v-btn
          >

          <v-btn
            rounded
            large
            block
            class="mt-5 mb-5"
            color="dark lighter--text"
            v-if="!isLoading"
            :disabled="isLoading"
            @click="closeDialog()"
            >CANCEL</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import axios from "../../axios/axios";

export default {
  data: () => ({
    title: "",
    isLoading: false,
    isNewTower: false,
    errorText: "",
    showError: false,
    tower: {},
    valid: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    colors: [
      { name: "Blue", color: "lightBlue" },
      { name: "Orange", color: "lighterOrange" },
      { name: "Green", color: "lightGreen" },
      { name: "Light", color: "light" },
      { name: "Lighter", color: "lighter" },
    ],
  }),
  props: {
    originalTower: {
      type: Object,
    },
  },
  mounted() {
    this.onShow();
  },
  updated() {
    this.onShow();
  },
  methods: {
    onShow() {
      this.tower = this.originalTower;
      if (this.originalTower._id) {
        this.title = "Edit Tower";
      } else {
        this.title = "New Tower";
        this.isNewTower = true;
      }
    },
    async saveClicked() {
      if (this.valid) {
        this.saveTower();
      } else {
        this.showError = true;
        this.errorText = "Invalid fields in form.";
      }
    },
    async saveTower() {
      this.isLoading = true;

      if (this.valid) {
        try {
          if (this.isNewTower) await axios.post("/towers", this.tower);
          else await axios.put("/towers", this.tower);

          this.tower = {};
          this.closeDialog();
        } catch (error) {
          alert(error.response.data.message);
        }
      }
      this.isLoading = false;
    },
    async deleteTower() {
      if (confirm(`You are about to delete the ${this.tower.name} tower.`)) {
        try {
          await axios.delete(`/towers/${this.tower._id}`);
          this.closeDialog();
        } catch (error) {
          alert(error.response.data.message);
        }
      }
    },
    closeDialog() {
      this.$emit("close");
    },
  },
};
</script>

<style>
</style>