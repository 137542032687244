<template>
  <div>
    <v-expansion-panels dark>
      <v-expansion-panel>
        <v-expansion-panel-header class="dark lighterOrange--text" @click="getBackups"
          >Backups</v-expansion-panel-header
        >
        <v-expansion-panel-content color="dark" class="light--text left-text">
          <p class="pt-5">
            Router configuration backups are human readable text that can be
            copied and pasted into a RouterOS terminal. They also make for good
            reference for cases when the tower is down an you need more
            information. Backups should occur twice per month and automatically
            contain the date in its' name.
          </p>

          <v-skeleton-loader
            v-if="isLoading"
            max-width="300"
            type="list-item"
          ></v-skeleton-loader>

          <div v-if="!isLoading">
            <v-btn
              v-for="backup in backups"
              :key="backup.link"
              text
              color="lightBlue"
              :href="backup.link"
              target="_blank"
              >{{ backup.name }}</v-btn
            >
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import axios from "../../../axios/axios";

export default {
  data: () => ({
    isLoading: true,
    headingClicked: false,
    backups: [],
  }),
  props: {
    tower: {
      type: Object,
    },
  },
  methods: {
    async getBackups() {
      if (!this.headingClicked) {
        this.headingClicked = true;
        try {
          const res = await axios.post(
            "/towers-monitor/backup-list",
            this.tower
          );
          this.backups = res.data;
        } catch (error) {
          alert(error.response.data.message);
        }
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>