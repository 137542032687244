<template>
  <div>
    <v-form v-model="valid">
      <v-row justify-sm="center">
        <v-col sm="4">
          <h1 class="text-h5 text-center light--text pb-5">Create Dataplan</h1>
        </v-col>
      </v-row>
      <v-row justify-sm="center">
        <v-col sm="4" class="pa-0">
          <v-text-field
            v-model="dataplan.displayName"
            label="Display Name"
            placeholder="Display Name"
            :disabled="isLoading"
            :rules="[rules.required]"
            dense
            outlined
            rounded
            required
            dark
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify-sm="center">
        <v-col sm="4" class="pa-0">
          <v-text-field
            v-model="dataplan.chrgOvrId"
            label="Charge Over ID"
            placeholder="Charge Over ID"
            :disabled="isLoading"
            :rules="[rules.required]"
            dense
            outlined
            rounded
            required
            dark
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="isLoading" justify-sm="center">
        <v-progress-circular
          indeterminate
          color="lighter"
        ></v-progress-circular>
      </v-row>

      <v-row justify-sm="center">
        <v-btn
          rounded
          large
          color="dark lightGreen--text"
          class="mt-3"
          v-if="!isLoading"
          :disabled="isLoading"
          @click="createDataplan()"
          >CREATE DATAPLAN</v-btn
        >
      </v-row>
      <v-row justify-sm="center">
        <v-col sm="5">
          <p v-if="showError" class="light--text mb-3">{{ errorText }}</p>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import axios from "../../axios/axios";

export default {
  data: () => ({
    isLoading: false,
    valid: false,
    errorText: "",
    showError: false,
    dataplan: {},
    rules: {
      required: (value) => !!value || "Required.",
    },
  }),
  methods: {
    submitForm() {
      if (this.valid) {
        this.createDataplan();
      } else {
        this.showError = true;
        this.errorText = "Invalid fields in form.";
      }
    },
    async createDataplan() {
      this.isLoading = true;
      try {
        this.showError = false;

        const res = await axios.post("/dataplans", this.dataplan);
        alert(res.data.message);
        this.dataplan = {};
      } catch (error) {
        this.showError = true;
        this.errorText = error.response.data.message;
      }
      this.isLoading = false;
    },
  },
};
</script>

<style>
</style>