<template>
  <div>
    <v-container>
      <h1 class="text-h3 font-weight-light light--text text-center pt-9 pb-14">MY ACCOUNT</h1>

      <change-user-password></change-user-password>
    </v-container>
  </div>
</template>

<script>
import ChangeUserPassword from '../components/Account/ChangeUserPassword.vue';

export default {
  components: {
    ChangeUserPassword
  }
}
</script>

<style>

</style>