<template>
  <div>
    <h1 class="text-h3 lightBlue--text pt-3 pb-3">Neighbors</h1>

    <v-btn
      color="darker lightBlue--text"
      large
      rounded
      :disabled="isLoading"
      @click="getNeighbors"
      >Refresh Neighbors
      <v-icon color="lightBlue" class="ml-2">mdi-refresh</v-icon>
    </v-btn>

    <p class="lightBlue--text mt-5">Neighbors ({{ neighbors.length }}):</p>

    <v-skeleton-loader
      v-if="isLoading"
      class="mx-auto mt-5"
      type="table-heading"
    ></v-skeleton-loader>

    <v-simple-table class="mt-5" v-if="!isLoading">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Identity</th>
            <th class="text-left">ip Address</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="neighbor in neighbors" :key="neighbor.id">
            <td class="lighter--text py-2">
              <span class="font-weight-bold">{{ neighbor.identity }}</span
              ><br /><span class="light--text">{{ neighbor.interface }}</span>
            </td>
            <td class="lighterOrange--text">{{ neighbor.address }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import axios from "../../../axios/axios";

export default {
  data: () => ({
    isLoading: true,
    headingClicked: false,
    neighbors: [],
  }),
  props: {
    tower: {
      type: Object,
    },
  },
  mounted() {
    this.getNeighbors();
  },
  methods: {
    headingClick() {
      if (!this.headingClicked) {
        this.headingClicked = true;
        this.getNeighbors();
      }
    },
    async getNeighbors() {
      this.isLoading = true;
      try {
        const res = await axios.post("/towers-monitor/neighbors", this.tower);
        this.neighbors = res.data;
      } catch (error) {
        alert(error.response.data.message);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style>
</style>