<template>
  <v-card dark class="pt-4 px-8 pb-4">
    <v-row justify-sm="center">
      <v-col sm="11">
        <v-card-title class="grey--text">Self Sign Up</v-card-title>
        <v-card-subtitle class="light--text">{{ towerName }}</v-card-subtitle>

        <v-skeleton-loader
          v-if="isLoading"
          type="table"
          dark
        ></v-skeleton-loader>

        <v-simple-table v-if="!isLoading && links.length > 0" class="pb-9" dark>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">no.</th>
                <th class="text-left">Expires</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="link, i in links"
                :key="link._id"
                class="pointer"
                @click="openEditDialog(link)"
              >
                <td class="light--text">{{ i+1 }}</td>
                <td class="light--text">{{ link.expirationDateTime }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-divider dark class="pt-2"></v-divider>

        <v-form v-model="valid">
          <v-card-title class="grey--text"
            >Create New Self Sign-up Link</v-card-title
          >

          <v-select
            :items="durations"
            item-text="label"
            item-value="milliseconds"
            label="Duration"
            v-model="newSelfSignUpLink.expirationMilliseconds"
            outlined
            rounded
            dense
            dark
            :rules="[rules.required]"
          ></v-select>

          <p class="ma-0 light--text">Dataplans</p>

          <v-checkbox
            class="ma-0"
            v-for="(dataplan, i) in dataplans"
            :key="i"
            v-model="newSelfSignUpLink.dataplans"
            :value="dataplan.chrgOvrId"
            :label="dataplan.displayName"
          ></v-checkbox>

          <v-btn
            rounded
            block
            color="darker lightBlue--text"
            v-if="!isLoading"
            :disabled="isLoading"
            @click="createLink"
            >Create Link
          </v-btn>

          <v-btn
            rounded
            block
            class="mt-5 mb-5"
            color="darker grey--text"
            v-if="!isLoading"
            :disabled="isLoading"
            @click="closeDialog"
            >CANCEL</v-btn
          >
        </v-form>
      </v-col>
    </v-row>

    <v-dialog v-model="editDialog" width="375">
      <v-card dark class="px-3 pb-9">
        <v-card-title class="light--text pb-7">Self Sign-up Link</v-card-title>

        <p class="grey--text text--darken-1 pb-4">
          Tower Name:
          <span class="lighterOrange--text">
            {{ selectedLink.towerName }}
          </span>
          <br />

          Expires on:
          <span class="light--text">
            {{ getDate(selectedLink.expirationDateTime) }}
          </span>
          <br />

          Link:
          <a class="lightBlue--text" :href="selectedLink.link" target="_blank">
            {{ selectedLink.link }}
          </a>
          <br />

          Dataplans:
          <span
            class="lightGreen--text"
            v-for="(dataplan, i) in selectedLink.dataplans"
            :key="i"
          >
            {{ getDataplanName(dataplan) }}, 
          </span>
        </p>

        <v-divider dark></v-divider>

        <v-btn color="darker darkRed--text mt-7" block rounded @click="deleteLink()"
          >Delete</v-btn
        >
        <v-btn color="darker grey--text mt-3" block rounded @click="editDialog = false">Close</v-btn>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "../../axios/axios";

export default {
  data: () => ({
    isLoading: true,
    editDialog: false,
    links: [],
    selectedLink: {},
    dataplans: [],
    newSelfSignUpLink: {
      dataplans: [],
      expirationMilliseconds: 0,
      towerName: "",
    },
    durations: [
      { label: "1 Hour", milliseconds: 3600000 },
      { label: "3 Hours", milliseconds: 10800000 },
      { label: "1 Day", milliseconds: 86400000 },
      { label: "2 Days", milliseconds: 172800000 },
      { label: "3 Days", milliseconds: 259200000 },
      { label: "1 Week", milliseconds: 604800000 },
      { label: "2 Weeks", milliseconds: 1209600000 },
    ],
    valid: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
  }),
  mounted() {
    this.getDataplans();
    this.getSelfSignUpLinks();
  },
  props: {
    towerName: {
      type: String,
    },
  },
  watch: {
    editDialog: function () {
      if (!this.editDialog) {
        this.getSelfSignUpLinks();
      }
    },
  },
  methods: {
    async createLink() {
      if (!this.valid) {
        alert("Missing Values");
        return;
      }

      this.isLoading = true;
      this.newSelfSignUpLink.towerName = this.towerName;

      try {
        const res = await axios.post(
          "/self-sign-up-links",
          this.newSelfSignUpLink
        );

        alert(res.data.message);
        this.newSelfSignUpLink.dataplans = [];
        this.getSelfSignUpLinks();
      } catch (error) {
        alert(error.response.data.message);
      }
      this.isLoading = false;
    },
    async getSelfSignUpLinks() {
      this.isLoading = true;
      try {
        const res = await axios.get("/self-sign-up-links/" + this.towerName);
        this.links = res.data;

        for (let i = 0; i < this.links.length; i++) {
          const date = this.links[i].expirationDateTime;
          const newDate = this.getDate(date);
          this.links[i].expirationDateTime = newDate;
        }
      } catch (error) {
        alert(error.response.data.message);
      }
      this.isLoading = false;
    },
    async getDataplans() {
      try {
        const res = await axios.get("/dataplans");
        this.dataplans = res.data;
      } catch (error) {
        alert(error.response.data.message);
      }
    },
    async deleteLink() {
      if (confirm(`You are about to delete this link.`)) {
        try {
          await axios.delete(`/self-sign-up-links/${this.selectedLink._id}`);
          this.editDialog = false;
          this.getSelfSignUpLinks();
        } catch (error) {
          alert(error.response.data.message);
        }
      }
    },
    openEditDialog(link) {
      this.editDialog = true;
      this.selectedLink = link;
    },
    getDate(date) {
      if (!date) return "";
      return new Date(date).toLocaleString();
    },
    getDataplanName(d) {
      for (const dataplan of this.dataplans) {
        if (dataplan.chrgOvrId.toString() === d.toString()) {
          return dataplan.displayName;
        }
      }
    },
    closeDialog() {
      this.$emit("close");
    },
  },
};
</script>

<style>
</style>