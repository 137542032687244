<template>
  <div>
    <p class="light--text">Customer Signature:</p>
    <VueSignaturePad
      id="signature"
      width="375px"
      height="125px"
      ref="signaturePad"
      :options="{ onEnd, penColor: '#EFEAE1' }"
    />
    <v-btn
      class="mt-2 lightGreen--text"
      color="darker"
      @click="clear"
      >Clear</v-btn
    >
  </div>
</template>

<script>
export default {
  props: {
    signature: {
      type: String,
    }
  },
  mounted() {
    this.show();
  },
  updated() {
    this.show();
  },
  methods: {
    show() {
      this.$refs.signaturePad.fromDataURL(this.signature);
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },
    onEnd() {
      const { data } = this.$refs.signaturePad.saveSignature();
      this.$emit("updateSignature", data);
    },
  },
};
</script>

<style scoped>
#signature {
  border-radius: 10px;
  background-image: linear-gradient(#353335, #353335);
}
</style>