<template>
  <div>
    <v-container class="text-center">
      <h1 class="text-h3 font-weight-light light--text text-center pt-9 pb-8">
        TOWERS
      </h1>

      <v-row justify-sm="center">
        <v-col sm="5">
          <v-text-field
            v-model="search"
            class="mt-5"
            color="lighter"
            label="Search..."
            placeholder="Search..."
            :disabled="isLoading"
            outlined
            rounded
            clearable
            dark
            @keyup="searchResults"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="justify-center" v-if="isLoading">
        <v-sheet v-for="index in 25" :key="index" dark color="darker">
          <v-skeleton-loader
            type="image"
            class="rounded-xl ma-5"
            width="160"
            height="160"
            dark
          ></v-skeleton-loader>
        </v-sheet>
      </v-row>

      <v-row class="justify-center" v-if="!isLoading">
        <tower-square
          v-for="tower of filteredTowers"
          :key="tower._id"
          :tower="tower"
          v-on:reload="getTowers"
          class="ma-5"
        ></tower-square>
      </v-row>

      <v-btn
        x-large
        rounded
        color="darkRed"
        @click="dialog = true"
        class="mt-16 mb-15"
      >
        <v-icon color="light">mdi-plus-thick</v-icon>
        <span class="light--text">NEW TOWER</span>
      </v-btn>
    </v-container>

    <v-dialog v-model="dialog" width="500">
      <tower-dialog v-on:close="closeDialog" :originalTower="{}"></tower-dialog>
    </v-dialog>
  </div>
</template>

<script>
import TowerSquare from "../components/Tower/TowerSquare.vue";
import TowerDialog from "../components/Tower/TowerDialog.vue";
import axios from "../axios/axios";

export default {
  data: () => ({
    isLoading: true,
    dialog: false,
    towers: [],
    filteredTowers: [],
    search: "",
    isSearching: false,
  }),
  components: {
    TowerSquare,
    TowerDialog,
  },
  watch: {
    dialog: function () {
      if (!this.dialog) {
        this.getTowers();
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    this.getTowers();
  },
  methods: {
    async getTowers() {
      this.isLoading = true;
      try {
        const res = await axios.get("/towers");
        this.towers = res.data;

        this.towers.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
        });
        this.isLoading = false;

        this.searchResults();
      } catch (error) {
        alert(error.response.data.message);
      }
      this.isLoading = false;
    },
    searchResults() {
      if (this.search) {
        this.filteredTowers = this.towers.filter(
          (tower) =>
            tower.name
              .toString()
              .toLocaleLowerCase()
              .indexOf(this.search.toLocaleLowerCase()) !== -1
        );
      } else {
        this.filteredTowers = this.towers;
      }
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>