<template>
  <v-card dark class="pt-4 px-8 pb-4">
    <v-card-title class="grey--text">Text Customers</v-card-title>

    <v-chip
      class="my-2 mr-4 lighter--text"
      color="darker"
      v-for="(number, i) in numbers"
      :key="i"
      close
      @click:close="removeNumber(i)"
      dark
      >{{ number }}</v-chip
    >

    <v-row class="pt-6 pb-4">
      <v-text-field
        v-model="newNumber"
        class="px-3"
        label="Add Number"
        placeholder="Add number"
        :disabled="isLoading"
        dense
        outlined
        rounded
        dark
      ></v-text-field>

      <v-btn
        rounded
        large
        color="darker lightBlue--text"
        v-if="!isLoading"
        :disabled="isLoading"
        @click="add"
        >Add
      </v-btn>
    </v-row>

    <v-divider class="pt-5"></v-divider>

    <v-textarea
      v-model="message"
      label="Message"
      placeholder="Message"
      :disabled="isLoading"
      outlined
      rounded
      auto-grow
      clearable
      dark
    ></v-textarea>

    <div class="text-center">
      <v-btn
        rounded
        large
        color="darker lighterOrange--text"
        v-if="!isLoading"
        :disabled="isLoading"
        @click="send"
        >SEND SMS TEXT MESSAGES
      </v-btn>
    </div>

    <p class="grey--text text--darken-1">
      Log:
      <span v-for="line in log.split('\n')" :key="line" class="light--text"
        >{{ line }}<br
      /></span>
    </p>

    <v-btn
      rounded
      block
      class="mt-5 mb-5"
      color="darker grey--text"
      v-if="!isLoading"
      :disabled="isLoading"
      @click="closeDialog"
      >CANCEL</v-btn
    >
  </v-card>
</template>

<script>
import axios from "../../axios/axios";

export default {
  data: () => ({
    isLoading: false,
    newNumber: "",
    message: "",
    log: "\n",
  }),
  props: {
    numbers: {
      type: Array,
    },
  },
  methods: {
    async send() {
      if (confirm(`You are about to send a SMS message`)) {
        this.isLoading = true;
        
        for (const number of this.numbers) {
          try {
            let payload = { message: this.message, number: number };
            const res = await axios.post("/sms", payload);

            this.log += res.data.message + "\n";
          } catch (error) {
            this.log += error.response.data.message + "\n";
          }
        }
      }
      this.isLoading = false;
    },
    add() {
      this.numbers.push(this.newNumber);
      this.newNumber = "";
    },
    removeNumber(index) {
      this.numbers.splice(index, 1);
    },
    closeDialog() {
      this.$emit("close");
    },
  },
};
</script>

<style>
</style>