<template>
  <div>
    <v-container>
      <v-btn text class="lightBlue--text" @click="backClicked()">
        <v-icon>mdi-arrow-left</v-icon>Back
      </v-btn>

      <div class="text-center mb-10">
        <h1
          :class="
            tower.color +
            '--text text-h3 font-weight-light text-center pt-6 pb-6'
          "
        >
          {{ tower.name }} Customers
        </h1>

        <v-btn
          large
          rounded
          color="dark lighter--text"
          link
          :to="'/tower-monitor/' + towerId"
        >
          Go to monitor
          <v-icon color="lighter">mdi-arrow-right</v-icon>
        </v-btn>
      </div>

      <v-divider dark></v-divider>

      <v-btn
        large
        rounded
        color="dark"
        link
        :to="'/customers/create?tower=' + tower.name"
        class="mt-7 mb-6"
      >
        <v-icon color="light">mdi-plus-thick</v-icon>
        <span class="light--text">NEW CUSTOMER</span>
      </v-btn>

      <v-btn
        large
        rounded
        color="dark"
        @click="textAll()"
        class="mt-7 mb-6 ml-4"
      >
        <v-icon color="lightGreen">mdi-cellphone-text</v-icon>
        <span class="lightGreen--text">TEXT ALL</span>
      </v-btn>

      <v-btn
        large
        rounded
        color="dark"
        @click="selfSignUp()"
        class="mt-7 mb-6 ml-4"
      >
        <v-icon color="grey">mdi-qrcode</v-icon>
        <span class="grey--text">SELF SIGN UP LINK</span>
      </v-btn>

      <v-skeleton-loader v-if="isLoading" type="table" dark></v-skeleton-loader>

      <v-data-table
        v-if="!isLoading"
        :headers="headers"
        :items="customers"
        :items-per-page="15"
        :search="search"
        :custom-filter="filterCustomers"
        class="elevation-1 light--text pointer"
        @click:row="rowClicked"
        dark
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search Customers..."
            class="mx-4 pt-4"
            color="lightGreen"
            dense
            outlined
            rounded
            clearable
            dark
          ></v-text-field>
        </template>
      </v-data-table>

      <v-dialog v-model="smsDialog" v-if="smsDialog" width="500">
        <text-all-dialog
          v-on:close="closeDialog"
          :numbers="numbers"
        ></text-all-dialog>
      </v-dialog>

      <v-dialog v-model="selfSignUpDialog" v-if="selfSignUpDialog" width="500">
        <self-sign-up-dialog v-on:close="closeDialog" :towerName="tower.name"></self-sign-up-dialog>
      </v-dialog>

      <v-dialog v-model="customerDialog" v-if="customerDialog" width="500">
        <customer-dialog
          v-on:close="closeDialog"
          :customer="selectedCustomer"
        ></customer-dialog>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import axios from "../axios/axios";
import TextAllDialog from "../components/Customer/TextAllDialog.vue";
import SelfSignUpDialog from "../components/Customer/SelfSignUpDialog.vue";
import CustomerDialog from "../components/Customer/CustomerDialog.vue";

export default {
  data: () => ({
    isLoading: true,
    isLoadingTower: true,
    tower: {},
    customerDialog: false,
    smsDialog: false,
    selfSignUpDialog: false,
    search: "",
    selectedCustomer: {},
    numbers: [],
    headers: [
      { text: "First Name", value: "firstName" },
      { text: "Last Name", value: "lastName" },
      { text: "Phone", value: "phone" },
      { text: "Address", value: "address1" },
    ],
    customers: [],
  }),
  components: {
    TextAllDialog,
    SelfSignUpDialog,
    CustomerDialog,
  },
  computed: {
    towerId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    this.getTower();
  },
  watch: {
    customerDialog: function () {
      if (!this.customerDialog) {
        this.getCustomers();
      }
    },
  },
  methods: {
    async getTower() {
      try {
        const res = await axios.get("/towers/" + this.towerId);
        this.tower = res.data;
      } catch (error) {
        alert(error.response.data.message);
      }
      this.isLoadingTower = false;
      this.getCustomers();
    },
    async getCustomers() {
      if (!this.isLoadingTower) {
        try {
          const res = await axios.get("customers/tower/" + this.towerId);
          this.customers = res.data;

          this.numbers = [];
          for (const customer of this.customers) {
            this.numbers.push(customer.phone);
          }
        } catch (error) {
          alert(error.response.data.message);
        }
        this.isLoading = false;
      }
    },
    rowClicked(customer) {
      this.selectedCustomer = customer;
      this.customerDialog = true;
    },
    filterCustomers(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    textAll() {
      this.smsDialog = true;
    },
    selfSignUp() {
      this.selfSignUpDialog = true;
    },
    closeDialog() {
      this.customerDialog = false;
      this.selfSignUpDialog = false;
      this.smsDialog = false;
    },
    backClicked() {
      history.back();
    },
  },
};
</script>

<style>
</style>