<template>
  <div>
    <v-container>
      <h1 class="text-h3 font-weight-light light--text text-center pt-9">
        DOCS
      </h1>

      <div class="text-right">
        <v-btn
          large
          rounded
          color="dark lightGreen--text"
          @click="dialog = true"
          class="mt-16 mb-2"
          >Upload Document</v-btn
        >
      </div>

      <v-skeleton-loader v-if="isLoading" type="table" dark></v-skeleton-loader>

      <v-simple-table v-if="!isLoading" dark>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Description</th>
              <th class="text-right">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="doc in docs" :key="doc._id">
              <td class="light--text pointer" @click="docClick(doc.src)">
                {{ doc.name }}
              </td>
              <td class="lightGreen--text pointer" @click="docClick(doc.src)">
                {{ doc.description }}
              </td>
              <td class="float-right">
                <v-btn icon @click="deleteDoc(doc._id)">
                  <v-icon color="lighterOrange">mdi-trash-can-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-dialog v-model="dialog" width="500">
        <doc-upload-dialog v-on:close="closeDialog"></doc-upload-dialog>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import axios from "../axios/axios";
import DocUploadDialog from "../components/Docs/DocUploadDialog.vue";

export default {
  data: () => ({
    isLoading: true,
    dialog: false,
    docs: [],
  }),
  mounted() {
    this.getDocs();
  },
  watch: {
    dialog: function () {
      if (!this.dialog) {
        this.getDocs();
      }
    },
  },
  components: {
    DocUploadDialog,
  },
  methods: {
    async getDocs() {
      this.isLoading = true;
      try {
        const res = await axios.get("/uploads");
        this.docs = res.data;
      } catch (error) {
        alert(error.response.data.message);
      }
      this.isLoading = false;
    },
    async deleteDoc(id) {
      if (confirm("Are you sure you want to delete this doc?")) {
        this.isLoading = true;
        try {
          await axios.delete("/uploads/doc/" + id);
          this.getDocs();
        } catch (error) {
          alert(error.response.data.message);
        }
        this.isLoading = false;
      }
    },
    docClick(link) {
      window.open(link, "_blank");
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>