<template>
  <div>
    <v-container>
      <h1 class="text-h3 font-weight-light light--text text-center pt-9">
        Admin Panel
      </h1>

      <users-list class="pb-8"></users-list>

      <v-divider class="pt-3" dark></v-divider>

      <dataplans-list class="pb-10"></dataplans-list>

      <v-divider class="pt-10" dark></v-divider>

      <user-creator class="pb-8"></user-creator>

      <v-divider class="pt-10" dark></v-divider>

      <dataplan-creator class="pb-6"></dataplan-creator>
    </v-container>
  </div>
</template>

<script>
import UsersList from "../components/Admin/UsersList.vue";
import DataplansList from "../components/Admin/DataplansList.vue";
import DataplanCreator from "../components/Admin/DataplanCreator.vue";
import UserCreator from "../components/Admin/UserCreator.vue";

export default {
  components: {
    UsersList,
    DataplansList,
    UserCreator,
    DataplanCreator,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
</style>