<template>
  <div>
    <h1 class="text-h3 lightGreen--text pt-3 pl-2 pb-3">PPPoe</h1>

    <v-btn
      color="darker lightGreen--text"
      large
      rounded
      :disabled="isLoading"
      @click="getPPPoe"
      >Refresh PPPoe
      <v-icon color="lightGreen" class="ml-2">mdi-refresh</v-icon>
    </v-btn>

    <p class="lightGreen--text mt-5">
      Active Connections ({{ activeConnections.length }}):
    </p>

    <v-skeleton-loader
      v-if="isLoading"
      class="mx-auto mt-5"
      type="table-heading"
    ></v-skeleton-loader>

    <div v-if="!isLoading">
      <v-simple-table dark class="mt-5">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Up Time</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="ppp in activeConnections" :key="ppp.id">
              <td class="white--text">{{ ppp.name }}</td>
              <td class="light--text">{{ ppp.uptime }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <p class="lightOrange--text mt-3">
        Non-active ({{ nonActiveConnections.length }}) - Most recent:
      </p>

      <v-simple-table class="darkRed darken-1">
        <template v-slot:default>
          <tbody>
            <tr v-for="ppp, i in nonActiveConnections" :key="ppp.id">
              <td class="lighter--text py-1">{{ i+1 }}</td>
              <td class="lighter--text py-1">
                <span class="font-weight-bold white--text">{{ ppp.name }}</span
                ><br />
                {{ ppp.password }} <br />
                {{ ppp["last-logged-out"] }} (Logged out)
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import axios from "../../../axios/axios";

export default {
  data: () => ({
    isLoading: true,
    headingClicked: false,
    activeConnections: [],
    nonActiveConnections: [],
  }),
  props: {
    tower: {
      type: Object,
    },
  },
  mounted() {
    this.getPPPoe();
  },
  methods: {
    async getPPPoe() {
      this.isLoading = true;
      try {
        const res = await axios.post("/towers-monitor/pppoe", this.tower);
        this.activeConnections = res.data.activeConnections;
        this.nonActiveConnections = res.data.nonActivePPP;
      } catch (error) {
        alert(error.response.data.message);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style>
</style>