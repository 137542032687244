<template>
  <v-card dark class="pt-4 px-8 pb-4">
    <v-card-title class="light--text">Upload Document</v-card-title>
    <v-form v-model="valid">
      <v-row justify-sm="center">
        <v-col sm="11">
          <v-text-field
            v-model="doc.name"
            label="Document Name"
            placeholder="Document Name"
            :disabled="isLoading"
            :rules="[rules.required]"
            outlined
            rounded
            clearable
            required
            dark
          ></v-text-field>

          <v-text-field
            v-model="doc.description"
            label="Document Description"
            placeholder="Document Description"
            :disabled="isLoading"
            :rules="[rules.required]"
            outlined
            rounded
            clearable
            required
            dark
          ></v-text-field>

          <input
            @change="fileChosen()"
            class="light--text pt-1 pb-7"
            type="file"
            ref="file"
            label="File input"
          />
          <v-text-field
            v-model="doc.src"
            label="Document Src"
            placeholder="Document Src"
            :disabled="isLoading"
            :rules="[rules.required]"
            outlined
            rounded
            clearable
            required
            dark
          ></v-text-field>

          <v-btn
            rounded
            large
            block
            color="lightBlue lighter--text"
            :disabled="isLoading"
            @click="uploadClicked()"
            >UPLOAD</v-btn
          >

          <v-btn
            rounded
            large
            block
            class="mt-5 mb-5"
            color="dark lighter--text"
            :disabled="isLoading"
            @click="closeDialog()"
            >CANCEL</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import axios from "../../axios/axios";

export default {
  data: () => ({
    isLoading: false,
    doc: { name: '', description: '', src: '' },
    valid: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
  }),
  computed: {
    baseURL() {
      return this.$store.getters.getBaseURL;
    },
  },
  methods: {
    async fileChosen() {
      try {
        let upload = new FormData();
        let file = this.$refs["file"].files[0];
        upload.append("file", file);

        const res = await axios.post("/uploads", upload);

        this.doc.src = this.baseURL + res.data.filename;
      } catch (error) {
        alert(error.response.data.message);
      }
    },
    async uploadClicked() {
      if (!this.valid) {
        alert("Missing values.");
        return;
      }

      this.isLoading = true;
      try {
        await axios.post("/uploads/doc", this.doc);
        this.doc = { name: '', description: '', src: '' };
        this.closeDialog();
      } catch (error) {
        alert(error.response.data.message);
      }
      this.isLoading = false;
    },
    closeDialog() {
      this.$emit("close");
    },
  },
};
</script>

<style>
</style>