<template>
  <div>
    <v-form v-model="valid">
      <v-row justify-sm="center">
        <v-col sm="4">
          <h1 class="text-h5 text-center light--text pb-5">Create User</h1>
        </v-col>
      </v-row>
      <v-row justify-sm="center">
        <v-col sm="4" class="pa-0">
          <v-text-field
            v-model="user.name"
            label="Name"
            placeholder="Name"
            :disabled="isLoading"
            :rules="[rules.required]"
            dense
            outlined
            rounded
            required
            dark
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify-sm="center">
        <v-col sm="4" class="pa-0">
          <v-text-field
            v-model="user.email"
            label="Email"
            placeholder="Email"
            :disabled="isLoading"
            :rules="[rules.required]"
            dense
            outlined
            rounded
            required
            dark
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify-sm="center">
        <v-col sm="4" class="pa-0">
          <v-text-field
            v-model="user.password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :type="showPassword ? 'text' : 'password'"
            label="Password"
            :disabled="isLoading"
            :rules="[rules.required, rules.min]"
            counter
            dense
            outlined
            rounded
            required
            dark
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify-sm="center">
        <v-col sm="4" class="pa-0">
          <v-select
            :items="userTypes"
            label="User Type"
            v-model="user.userType"
            outlined
            rounded
            dense
            dark
            :rules="[rules.required]"
          ></v-select>
        </v-col>
      </v-row>

      <v-row v-if="isLoading" justify-sm="center">
        <v-progress-circular
          indeterminate
          color="lighter"
        ></v-progress-circular>
      </v-row>

      <v-row justify-sm="center">
        <v-btn
          rounded
          large
          color="dark lightBlue--text"
          class="mt-3"
          v-if="!isLoading"
          :disabled="isLoading"
          @click="createUser()"
          >CREATE USER</v-btn
        >
      </v-row>
      <v-row justify-sm="center">
        <v-col sm="5">
          <p v-if="showError" class="light--text mb-3">{{ errorText }}</p>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import axios from "../../axios/axios";

export default {
  data: () => ({
    isLoading: false,
    valid: false,
    showPassword: false,
    errorText: "",
    showError: false,
    user: {},
    userTypes: ["admin", "guest"],
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
  }),
  methods: {
    submitForm() {
      if (this.valid) {
        this.createUser();
      } else {
        this.showError = true;
        this.errorText = "Invalid fields in form.";
      }
    },
    async createUser() {
      this.isLoading = true;
      try {
        this.showError = false;

        const res = await axios.post("/users", this.user);
        alert(res.data.message);
        this.user = {};
      } catch (error) {
        this.showError = true;
        this.errorText = error.response.data.message;
      }
      this.isLoading = false;
    },
  },
};
</script>

<style>
</style>