<template>
  <div>
    <v-container class="text-center">
      <h1 class="text-h3 font-weight-light light--text text-center pt-9 pb-6">
        CUSTOMERS
      </h1>

      <v-btn
        large
        rounded
        color="darkRed"
        link
        to="/customers/create"
        class="mb-12"
      >
        <v-icon color="light">mdi-plus-thick</v-icon>
        <span class="light--text">NEW CUSTOMER</span>
      </v-btn>

      <v-skeleton-loader v-if="isLoading" type="table" dark></v-skeleton-loader>

      <v-data-table
        v-if="!isLoading"
        :headers="headers"
        :items="customers"
        :items-per-page="15"
        :search="search"
        :custom-filter="filterCustomers"
        class="elevation-1 light--text pointer"
        @click:row="rowClicked"
        dark
      >
        <template v-slot:top>
          <div class="light--text text-left pt-3">
          <span class="pl-5">Search using lower case characters.</span>
          <v-text-field
            v-model="search"
            label="Search Customers..."
            class="mx-4 pt-4"
            color="lightGreen"
            dense
            outlined
            rounded
            clearable
            dark
          ></v-text-field>
          </div>
        </template>
      </v-data-table>

      <v-dialog v-model="dialog" v-if="dialog" width="600">
        <customer-dialog
          v-on:close="closeDialog"
          :customer="selectedCustomer"
        ></customer-dialog>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import axios from "../axios/axios";
import CustomerDialog from "../components/Customer/CustomerDialog.vue";

export default {
  data: () => ({
    isLoading: true,
    dialog: false,
    search: "",
    selectedCustomer: {},
    headers: [
      { text: "First Name", value: "firstName" },
      { text: "Last Name", value: "lastName" },
      { text: "Phone", value: "phone" },
      { text: "Tower", value: "tower" },
    ],
    customers: [],
  }),
  components: {
    CustomerDialog,
  },
  watch: {
    dialog: function () {
      if (!this.dialog) {
        this.getCustomers();
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    this.getCustomers();
  },
  methods: {
    async getCustomers() {
      this.isLoading = true;

      try {
        const res = await axios.get("/customers");
        this.customers = res.data;
      } catch (error) {
        alert(error.response.data.message);
      }

      this.isLoading = false;
    },
    rowClicked(customer) {
      this.selectedCustomer = customer;
      this.dialog = true;
    },
    filterCustomers(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleLowerCase()
          .indexOf(search.toLocaleLowerCase()) !== -1
      );
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>