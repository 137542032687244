<template>
  <v-app-bar
    fixed
    color="dark"
    elevation="1"
    app
    dark
  >
    <v-img
      src="../../assets/main-logo.png"
      max-width="100"
      class="mr-5"
    ></v-img>

    <v-btn text color="light" link to="/towers" v-if="isLoggedIn">Towers</v-btn>
    <v-btn text color="light" link to="/customers" v-if="isLoggedIn"
      >Customers</v-btn
    >
    <v-btn text color="light" link to="/docs" v-if="isLoggedIn">Docs</v-btn>

    <v-spacer></v-spacer>

    <v-menu offset-y dark>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon color="lighter">mdi-account</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item link to="/login" v-if="!isLoggedIn">
          <v-list-item-icon>
            <v-icon color="lighter">mdi-login</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="light--text">Login</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/account" :disabled="!isLoggedIn">
          <v-list-item-icon>
            <v-icon color="lighter">mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="light--text">My Account</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          to="/admin"
          :disabled="!isLoggedIn || userType !== 'admin'"
        >
          <v-list-item-icon>
            <v-icon color="lighter">mdi-account-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="light--text">Admin</v-list-item-title>
        </v-list-item>

                <v-list-item @click="logOut()" v-if="isLoggedIn">
          <v-list-item-icon>
            <v-icon color="lighter">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="light--text">Logout</v-list-item-title>
        </v-list-item>
        
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  computed: {
    isLoggedIn() {
      return this.$store.getters.getIsLoggedIn;
    },
    userType() {
      return this.$store.getters.getUserType;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style>
</style>