<template>
  <v-sheet class="wrapper light--text pt-16" color="darker">
      <h1 class="pt-15">Ubiquity Internet</h1>
      <p class="pt-3">Name: {{ customer.firstName }} {{ customer.lastName }}</p>
      <p>Phone: {{ customer.phone }}</p>
      <p>Email: {{ customer.email }}</p>

      <v-simple-table dark>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Id.</th>
              <th class="text-left">Service</th>
              <th class="text-left">Charged amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ customer.dataPlan }}</td>
              <td>{{ service }}</td>
              <td>{{ price }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <p class="light--text pt-3" v-if="customer.notes">
        Additional notes:
        <span
          v-for="line in customer.notes.split('\n')"
          :key="line"
          ><br />{{ line }}
        </span>
      </p>
  </v-sheet>
</template>

<script>
import axios from "../axios/axios";

export default {
  data: () => ({
    isLoading: true,
    customer: {},
    price: "",
    service: "",
  }),
  mounted() {
    this.getCustomer();
  },
  computed: {
    customerId() {
      return this.$route.params.id;
    },
  },
  methods: {
    async getCustomer() {
      this.isLoading = true;
      try {
        const res = await axios.get("/customers/" + this.customerId);
        this.customer = res.data;

        this.getPrice();
        this.getDataplanName();
      } catch (error) {
        alert(error.response.data.message);
      }
      this.isLoading = false;
    },
    async getPrice() {
      this.isLoading = true;
      try {
        const res = await axios.get(
          "/customers/chargeOverPrice/" + this.customer.dataPlan
        );
        if (res.data.price) {
          this.price = '$' + res.data.price;
        } else {
          this.price = 'Price not found'
        }
      } catch (error) {
        alert(error.response.data.message);
      }
      this.isLoading = false;
    },
    async getDataplanName() {
      this.isLoading = true;
      try {
        const res = await axios.get("/dataplans");
        const dataplans = res.data;

        for (const dataplan of dataplans) {
          if (dataplan.chrgOvrId.toString() === this.customer.dataPlan) {
            this.service = dataplan.displayName;
            break;
          }
        }
      } catch (error) {
        alert(error.response.data.message);
      }
      this.isLoading = false;
    },
    printClicked() {
      window.print();
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 600px;
  max-width: 95%;
  margin: 0 auto;
  padding-top: 50px;
}

@media print {
  .wrapper {
    width: 100vw;
    height: 500px;
    position: absolute;
    top: -80px;
    left: 0;
    z-index: 1000;
  }
  button {
    visibility: hidden;
  }
}
</style>