<template>
  <div>
      <v-container fluid class="text-center">
        <v-form v-model="valid">
          <v-row justify-sm="center">
            <v-col sm="5">
              <h1 class="text-h4 font-weight-light light--text mt-16">Login</h1>
            </v-col>
          </v-row>
          <v-row justify-sm="center">
            <v-col sm="5">
              <v-text-field
                v-model="username"
                class="mt-7"
                label="Username"
                placeholder="Username"
                :disabled="isLoading"
                :rules="[rules.required]"
                outlined
                rounded
                required
                dark
                @keyup.enter="submitForm()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify-sm="center">
            <v-col sm="5">
              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'"
                name="input-10-1"
                label="Password"
                :disabled="isLoading"
                :rules="[rules.required, rules.min]"
                counter
                clearable
                outlined
                rounded
                required
                dark
                @keyup.enter="submitForm()"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="isLoading" justify-sm="center">
            <v-col sm="5">
              <v-progress-circular indeterminate color="lighter"></v-progress-circular>
            </v-col>
          </v-row>

          <v-row justify-sm="center">
            <v-col sm="5">
              <v-btn
                rounded
                x-large
                color="lightBlue lighter--text"
                v-if="!isLoading"
                :disabled="isLoading"
                @click="submitForm()"
              >SUBMIT</v-btn>
            </v-col>
          </v-row>
          <v-row justify-sm="center">
            <v-col sm="5">
              <p v-if="showError" class="light--text mb-16">{{ errorText }}</p>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
  </div>
</template>

<script>
import axios from "../axios/axios";
import router from "../router/index";

export default {
  data: () => ({
    isLoading: false,
    valid: false,
    showPassword: false,
    errorText: "",
    showError: false,
    username: "",
    password: "",
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 6 || "Min 6 characters",
    },
  }),
  methods: {
    submitForm() {
      if (this.valid) {
        this.signIn();
      } else {
        this.showError = true;
        this.errorText = "Invalid fields in form.";
      }
    },
    async signIn() {
      try {
        this.isLoading = true;
        this.showError = false;

        const res = await axios.post("/auth/login", {
            email: this.username,
            password: this.password
        });

        this.$store.dispatch({
          type: "login",
          email: res.data.email,
          name: res.data.name,
          token: res.data.token,
          expiresIn: res.data.expiresIn,
          userType: res.data.userType
        });

        this.isLoading = false;
        router.push({ path: "/" });
      } catch (error) {
        this.showError = true;
        this.errorText = error.response.data.message;
        this.isLoading = false;
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
</style>