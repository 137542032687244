<template>
  <v-card dark class="pt-4 px-8 pb-4">
    <v-row justify-sm="center" align="center" class="pb-2">
      <v-card-title class="grey--text">View Customer</v-card-title>
      <v-btn icon color="darkRed" link :to="'/customers/' + customer._id">
        <v-icon color="light">mdi-pencil</v-icon>
      </v-btn>
    </v-row>

    <v-row justify-sm="center">
      <v-col sm="11">
        <p class="grey--text text--darken-1 mb-0">
          Name:
          <span class="light--text">
            {{ customer.firstName }} {{ customer.lastName }}
          </span>
          <br />

          Company:
          <span class="light--text">
            {{ customer.company }}
          </span>
          <br />

          Dataplan:
          <span class="light--text">
            {{ dataplanName }}
          </span>
          <br />

          Tower:
          <span class="lightGreen--text">
            {{ customer.tower }}
          </span>
          <br />

          Phone:
          <span class="lighterOrange--text">
            {{ customer.phone }}
          </span>
          <br />

          Email:
          <span class="lightBlue--text">
            {{ customer.email }}
          </span>
          <br />

          Address:
          <span class="light--text">
            {{ customer.address1 }} {{ customer.address2 }} {{ customer.city }},
            {{ customer.state }} {{ customer.zip }}
          </span>
          <br />

          Date of Birth:
          <span class="light--text">
            {{ getDate(customer.birthday) }}
          </span>
        </p>

        <p v-if="customer.notes" class="grey--text text--darken-1">
          Notes:
          <span
            v-for="line in customer.notes.split('\n')"
            :key="line"
            class="light--text"
            >{{ line }}<br
          /></span>
        </p>

        <v-divider class="pb-3"></v-divider>

        <p class="grey--text text--darken-1">
          Sales Person:
          <span class="light--text">
            {{ customer.salesName }}
          </span>
          <br />

          Installer:
          <span class="light--text">
            {{ customer.installersName }}
          </span>
          <br />

          Install Date:
          <span class="light--text">
            {{ getDate(customer.installDate) }}
          </span>
          <br />
        </p>

        <v-divider class="pb-3"></v-divider>

        <p class="grey--text text--darken-1">
          Previous Carrier:
          <span class="light--text">
            {{ customer.previousCarrier }}
          </span>
          <br />

          Previous Account #:
          <span class="light--text">
            {{ customer.previousAccountNumber }}
          </span>
          <br />

          Previous Account Pin:
          <span class="light--text">
            {{ customer.previousAccountPin }}
          </span>
          <br />
        </p>

        <span class="light--text">Customer Signature:</span>
        <v-img
          width="188"
          height="63"
          class="dark rounded-lg mt-2"
          :src="customer.signature"
        ></v-img>

        <v-row class="pt-9 pb-4">
          <v-btn
            rounded
            large
            class="ml-4"
            color="darker lightBlue--text"
            v-if="!isLoading"
            :disabled="isLoading"
            link
            :to="'/customers/' + customer._id"
            >Edit
          </v-btn>

          <v-btn
            rounded
            large
            class="ml-4 mr-4"
            color="darker lightOrange--text"
            v-if="!isLoading"
            :disabled="isLoading"
            @click="deleteCustomer()"
            >Delete
          </v-btn>

          <v-btn
            fab
            small
            color="darker"
            v-if="!isLoading"
            :disabled="isLoading"
            :href="'/customer-print/' + customer._id"
            target="_blank"
            ><v-icon color="grey">mdi-printer</v-icon>
          </v-btn>
        </v-row>

        <v-btn
          rounded
          block
          large
          class="mt-3 mb-5"
          color="darker grey--text"
          v-if="!isLoading"
          :disabled="isLoading"
          @click="closeDialog()"
          >CANCEL</v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import axios from "../../axios/axios";

export default {
  data: () => ({
    isLoading: true,
    dataplans: [],
    dataplanName: "",
  }),
  props: {
    customer: {
      type: Object,
    },
  },
  mounted() {
    this.getDataplans();
  },
  methods: {
    async deleteCustomer() {
      if (
        confirm(
          `You are about to delete ${
            this.customer.firstName + " " + this.customer.lastName
          }'s record.`
        )
      ) {
        this.isLoading = true;
        if (!this.isNewCustomer) {
          try {
            await axios.delete("/customers/" + this.customer._id);
            this.closeDialog();
          } catch (error) {
            alert(error.response.data.message);
          }
        }
        this.isLoading = false;
      }
    },
    async getDataplans() {
      this.isLoading = true;
      try {
        const res = await axios.get("/dataplans");
        this.dataplans = res.data;

        for (const dataplan of this.dataplans) {
          if (dataplan.chrgOvrId.toString() === this.customer.dataPlan) {
            this.dataplanName = dataplan.displayName;
            break;
          }
        }
      } catch (error) {
        alert(error.response.data.message);
      }
      this.isLoading = false;
    },
    getDate(date) {
      if (!date) return "";
      return new Date(date).toLocaleDateString();
    },
    closeDialog() {
      this.$emit("close");
    },
  },
};
</script>

<style>
</style>