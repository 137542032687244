<template>
  <v-row class="d-flex justify-end">
    <v-menu offset-y dark>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon large v-bind="attrs" v-on="on" class="mx-2">
          <v-icon color="lighter">mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="dialog = true">
          <v-list-item-icon>
            <v-icon color="lighter">mdi-pencil</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="light--text">Edit</v-list-item-title>
        </v-list-item>
        <v-list-item class="darkRed" @click="deleteTower">
          <v-list-item-icon>
            <v-icon color="lighter">mdi-delete</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="light--text">Delete</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog v-model="dialog" width="500">
      <tower-dialog
        v-on:close="dialog = false"
        :originalTower="tower"
      ></tower-dialog>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "../../axios/axios";
import TowerDialog from "./TowerDialog.vue";

export default {
  data: () => ({
    dialog: false,
  }),
  components: {
    TowerDialog,
  },
  props: {
    tower: {
      type: Object,
    },
  },
  watch: {
    dialog: function () {
      if (!this.dialog) {
        this.$emit("reload");
      }
    },
  },
  methods: {
    async deleteTower() {
      if (confirm(`You are about to delete the ${this.tower.name} tower.`)) {
        try {
          await axios.delete(`/towers/${this.tower._id}`);
          this.$emit("reload");
        } catch (error) {
          alert(error.response.data.message);
        }
      }
    },
  },
};
</script>

<style>
</style>