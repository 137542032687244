import Vue from 'vue'
import VueRouter from 'vue-router'

import AdminView from '../views/AdminView.vue'
import AllCustomersView from '../views/AllCustomersView.vue'
import AllTowersView from '../views/AllTowersView.vue'
import DocsView from '../views/DocsView.vue'
import LoginView from '../views/LoginView.vue'
import MyAccountView from '../views/MyAccountView.vue'
import PrintView from '../views/PrintView.vue'
import CustomerEditView from '../views/CustomerEditView.vue'
import TowerCustomersView from '../views/TowerCustomersView.vue'
import TowerMonitorView from '../views/TowerMonitorView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/admin',
    name: 'Admin',
    component: AdminView,
    exact: true
  },
  {
    path: '/customers',
    name: 'All Customers',
    component: AllCustomersView,
    exact: true
  },
  {
    path: '/',
    name: 'Home',
    component: AllTowersView,
    exact: true
  },
  {
    path: '/towers',
    name: 'All Towers',
    component: AllTowersView,
    exact: true
  },
  {
    path: '/docs',
    name: 'Docs',
    component: DocsView,
    exact: true
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    exact: true
  },
  {
    path: '/account',
    name: 'My Account',
    component: MyAccountView,
    exact: true
  },
  {
    path: '/customers/:id',
    name: 'Customer Edit',
    component: CustomerEditView,
    props: true
  },
  {
    path: '/customer-print/:id',
    name: 'Print',
    component: PrintView,
    props: true
  },
  {
    path: '/tower-customers/:id',
    name: 'Tower Customers',
    component: TowerCustomersView,
    props: true
  },
  {
    path: '/tower-monitor/:id',
    name: 'Tower Monitor',
    component: TowerMonitorView,
    props: true
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
